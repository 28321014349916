import { setValueAndTagOnServer } from '../useWorkpaper/processCommand';
import {
  SOURCE_DATA_CONNECTION,
  INT_TAX_RATE,
  STATE_TAX_RATE,
  STATE_APPORTION,
  CELL_REVIEW,
  DATA_LINK,
} from '../../../_shared/DataReference/ReferenceType';
import GC from '../../../../SpreadSheets';
import { momentDateFormats } from '../Spreadsheet/_spreadsheets/utils';
const moment = require('moment');

export function getCellTag(spreadsheet, row, column) {
  const tag = spreadsheet.getTag(row, column, 3);
  if (tag) {
    const escapedString = tag.replaceAll('\\', '');
    return JSON.parse(escapedString);
  }

  return null;
}

export function setCellTag(spreadsheet, row, column, data) {
  spreadsheet.setTag(row, column, JSON.stringify(data));
}

export function isEmptyObject(obj) {
  try {
    if (typeof obj === 'string') {
      obj = JSON.parse(obj);
    }
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  } catch {
    return false;
  }
}

export async function generateOutputRequest(dataReferenceQueue, sheetName) {
  const sdcOutputRequests = generateSdcOutputRequest(dataReferenceQueue, sheetName);
  const intTaxRateOutputRequests = generateIntTaxRateOutputRequests(dataReferenceQueue, sheetName);
  const stateTaxRateOutputRequests = generateStateTaxRateOutputRequests(dataReferenceQueue, sheetName);
  const stateApportionOutputRequests = generateStateApportionOutputRequests(dataReferenceQueue, sheetName);
  const dataLinkOutputRequests = generateDataLinkOutputRequests(dataReferenceQueue);

  const outPutRequest = [
    ...sdcOutputRequests,
    ...intTaxRateOutputRequests,
    ...stateTaxRateOutputRequests,
    ...stateApportionOutputRequests,
    ...dataLinkOutputRequests,
  ];

  return outPutRequest;
}

function generateSdcOutputRequest(dataReferenceQueue, sheetName) {
  const sourceConnections = dataReferenceQueue.current.filter(({ type }) => type === SOURCE_DATA_CONNECTION);
  let sourceConnectionRequest = [];

  if (sourceConnections.length) {
    sourceConnectionRequest = sourceConnections.map(
      ({ id, row, column, output: outputId, outputField: fieldToSum, filters: criteria, type }) => ({
        key: JSON.stringify({ row, column, sheetName, type, id }),
        outputId,
        fieldToSum,
        criteria,
      })
    );
  }

  return sourceConnectionRequest;
}

function generateIntTaxRateOutputRequests(dataReferenceQueue, sheetName) {
  const intTaxRateConnections = dataReferenceQueue.current.filter(({ type }) => type === INT_TAX_RATE);
  let intTaxRateRequest = [];

  if (intTaxRateConnections.length) {
    intTaxRateRequest = intTaxRateConnections.map(
      ({ id, row, column, country, periodStartDate, taxableIncome, type }) => ({
        key: JSON.stringify({ row, column, sheetName, type, id }),
        country,
        periodStartDate,
        taxableIncome,
      })
    );
  }

  return intTaxRateRequest;
}

function generateStateTaxRateOutputRequests(dataReferenceQueue, sheetName) {
  const stateTaxRateConnections = dataReferenceQueue.current.filter(({ type }) => type === STATE_TAX_RATE);
  let stateTaxRateRequest = [];

  if (stateTaxRateConnections.length) {
    stateTaxRateRequest = stateTaxRateConnections.map(
      ({ id, row, column, jurisdiction, periodStartDate, taxableIncome, type }) => ({
        key: JSON.stringify({ row, column, sheetName, type, id }),
        jurisdiction,
        ...{ startDate: periodStartDate },
        ...{ income: taxableIncome },
      })
    );
  }

  return stateTaxRateRequest;
}

function generateStateApportionOutputRequests(dataReferenceQueue, sheetName) {
  const stateApportionConnections = dataReferenceQueue.current.filter(({ type }) => type === STATE_APPORTION);
  let stateApportionRequest = [];

  if (stateApportionConnections.length) {
    stateApportionRequest = stateApportionConnections.map(({ id, row, column, state, date, factor, type }) => ({
      key: JSON.stringify({ row, column, sheetName, type, id }),
      state,
      date,
      type: factor,
    }));
  }

  return stateApportionRequest;
}

function generateDataLinkOutputRequests(dataReferenceQueue) {
  const dataLinkConnections = dataReferenceQueue.current.filter(({ type }) => type === DATA_LINK);
  let dataLinkRequest = [];

  if (dataLinkConnections.length) {
    dataLinkRequest = dataLinkConnections.map(({ id, row, column, type, name, sheetName, parameters }) => ({
      key: JSON.stringify({ row, column, name, sheetName, type, id }),
      parameters,
    }));
  }

  return dataLinkRequest;
}

export function formulaMatch(dataReferences, targetParameter) {
  const reference = dataReferences.current.find(x => x.id === targetParameter.id);
  if (!reference) {
    return false;
  }

  const type = targetParameter.type;
  if (type === SOURCE_DATA_CONNECTION) {
    if (!reference.parameters) {
      return false;
    }

    const {
      parameters: { OutputId, OutputField, Filters, Symbol },
    } = targetParameter;

    const referenceParameter = JSON.parse(reference.parameters);
    if (
      OutputId !== referenceParameter.OutputId ||
      OutputField !== referenceParameter.OutputField ||
      Filters !== referenceParameter.Filters ||
      Symbol !== referenceParameter.Symbol
    ) {
      return false;
    }
  }

  if (type === INT_TAX_RATE) {
    if (!reference.parameters) {
      return false;
    }

    const {
      parameters: { Country, PeriodStartDate, TaxableIncome, Symbol },
    } = targetParameter;

    const referenceParameter = JSON.parse(reference.parameters);
    if (
      Country !== referenceParameter.Country ||
      PeriodStartDate !== referenceParameter.PeriodStartDate ||
      TaxableIncome !== referenceParameter.TaxableIncome ||
      Symbol !== referenceParameter.Symbol
    ) {
      return false;
    }
  }

  if (type === STATE_TAX_RATE) {
    if (!reference.parameters) {
      return false;
    }

    const {
      parameters: { Jurisdiction, PeriodStartDate, TaxableIncome, Symbol },
    } = targetParameter;

    const referenceParameter = JSON.parse(reference.parameters);
    if (
      Jurisdiction !== referenceParameter.Jurisdiction ||
      PeriodStartDate !== referenceParameter.PeriodStartDate ||
      TaxableIncome !== referenceParameter.TaxableIncome ||
      Symbol !== referenceParameter.Symbol
    ) {
      return false;
    }
  }

  if (type === STATE_APPORTION) {
    if (!reference.parameters) {
      return false;
    }

    const {
      parameters: { State, Date, Type, Symbol },
    } = targetParameter;

    const referenceParameter = JSON.parse(reference.parameters);
    const targetDate = moment(Date.toString()).format('MM/DD/YYYY');
    const parameterDate = moment(referenceParameter.Date.toString()).format('MM/DD/YYYY');
    if (
      State !== referenceParameter.State ||
      targetDate !== parameterDate ||
      Type !== referenceParameter.Type ||
      Symbol !== referenceParameter.Symbol
    ) {
      return false;
    }
  }

  return true;
}

export async function generateCellMetadata(dataReferenceQueue, dataReferenceValues) {
  return dataReferenceQueue.current.map(({ id, row, column, parameters, type, sheetName }) => {
    const resolvedValue = dataReferenceValues.current.find(x => {
      if (x.key) {
        const key = JSON.parse(x.key);
        return row === key.row && column === key.column && sheetName === key.sheetName;
      }
      return null;
    });
    return {
      referenceId: id,
      row,
      column,
      parameters: JSON.stringify(parameters),
      referenceType: type,
      sheetName,
      oldValue: resolvedValue?.value,
      newValue: resolvedValue?.value,
      extraData: resolvedValue?.extraData,
    };
  });
}

export function returnReferenceValue(reference) {
  if (reference) {
    if (reference.value === undefined) {
      return undefined;
    } else if (reference.value === '') {
      return GC.Spread.CalcEngine.Errors.NotAvailable;
    } else {
      return reference.value;
    }
  }
  return reference;
}

export function returnRecalcReferenceValue(value) {
  return value === GC.Spread.CalcEngine.Errors.NotAvailable ? '' : value;
}

export function resolveNewReferenceValue(sourceReferenceQueue, row, column, sheet) {
  if (sourceReferenceQueue.current?.length > 0) {
    const reference = sourceReferenceQueue.current.find(x => {
      const key = JSON.parse(x.key);
      return key.row === row && key.column === column && key.sheetName === sheet;
    });
    return returnReferenceValue(reference);
  }
}

export function resolveExistingReferenceValue(sourceReferenceQueue, cellTag, referenceType, sheet) {
  if (sourceReferenceQueue.current?.length > 0 && cellTag.references.length > 0) {
    const cellFormulaReference = cellTag.references.find(x => x.type === referenceType);
    const reference = sourceReferenceQueue.current.find(x => x.id === cellFormulaReference.id && x.sheetName === sheet);
    return returnReferenceValue(reference);
  }

  return '';
}

export function createTempInMemoryDataReferences(
  spreadsheet,
  dataReferences,
  dataReferenceQueue,
  isUndoDeleteCommandAction = false
) {
  if (isUndoDeleteCommandAction) {
    return;
  }

  const newReferences = [];
  dataReferenceQueue.current.forEach(({ id, row, column, parameters, type, sheetName, workpaperId }) => {
    const reference = dataReferences.current.find(x => x.id === id);
    if (reference) {
      reference.value = spreadsheet?.getValue(reference.row, reference.column);
    } else {
      const params = JSON.stringify(parameters);
      newReferences.push({ id, row, column, parameters: params, type, sheetName, value: undefined, workpaperId });
    }
  });
  dataReferences.current = [...dataReferences.current, ...newReferences];
}

export async function updateInMemoryDataReferences(dataReferences, dataReferenceQueue, dataReferenceValues) {
  const newReferences = [];
  const existingReferences = [];

  dataReferenceValues.current.forEach(({ key, value }) => {
    const { id, row, column, sheetName, type } = JSON.parse(key);
    const referenceExist = dataReferences.current.some(
      x => x.row === row && x.column === column && x.sheetName === sheetName
    );
    const parameters = JSON.stringify(
      dataReferenceQueue.current.find(x => x.row === row && x.column === column && x.sheetName === sheetName)
        ?.parameters
    );
    const reference = { id, row, column, parameters, type, sheetName, value };

    if (referenceExist) {
      existingReferences.push(reference);
    } else {
      newReferences.push(reference);
    }
  });

  const updatedReferences = dataReferences.current.map(reference => {
    const valueExist = dataReferenceValues.current.find(x => {
      const { row, column, sheetName } = JSON.parse(x.key);
      return row === reference.row && column === reference.column && sheetName === reference.sheetName;
    });

    return valueExist ? { ...reference, value: valueExist.value } : reference;
  });

  dataReferences.current = [...updatedReferences, ...newReferences];
}

export async function updateInMemoryDataReferencesQueue(dataReferences, sourceReferenceQueue, dataReferenceValues) {
  sourceReferenceQueue.current = sourceReferenceQueue.current.map(referenceRecalc => {
    const resolvedValue = dataReferenceValues.current.find(x => {
      const { row, column, sheetName } = JSON.parse(x.key);
      return (
        row === referenceRecalc.row && column === referenceRecalc.column && sheetName === referenceRecalc.sheetName
      );
    });

    dataReferences.current = dataReferences.current.map(reference => {
      const { row, column, type, sheetName } = reference;

      if (
        row === referenceRecalc.row &&
        column === referenceRecalc.column &&
        type === referenceRecalc.type &&
        sheetName === referenceRecalc.sheetName
      ) {
        return {
          ...reference,
          value: resolvedValue ? returnRecalcReferenceValue(resolvedValue.value) : undefined,
          parameters: JSON.stringify(referenceRecalc.parameters),
          extraData: resolvedValue?.extraData,
        };
      } else {
        return reference;
      }
    });

    return {
      ...referenceRecalc,
      value: resolvedValue ? returnRecalcReferenceValue(resolvedValue.value) : undefined,
      extraData: resolvedValue?.extraData,
    };
  });
}

export async function updateDataReferenceQueue(spreadsheet, dataReferenceQueue, dataReferenceValues) {
  let references = dataReferenceQueue.current;
  let values = dataReferenceValues.current;
  if (references.length && values.length) {
    values.forEach(resolvedValue => {
      if (resolvedValue?.value === undefined || resolvedValue?.value === null) {
        return;
      }

      const { row: resolvedRow, column: resolvedColumn, sheetName: resolvedSheetName } = JSON.parse(resolvedValue.key);
      let cellValue = spreadsheet.getValue(resolvedRow, resolvedColumn)?.toString();
      if (cellValue === GC.Spread.CalcEngine.Errors.NotAvailable) {
        cellValue = '';
      }

      references = references.filter(({ row, column, sheetName }) => {
        return !(resolvedRow === row && resolvedColumn === column && resolvedSheetName === sheetName);
      });

      if (cellValue === resolvedValue.value) {
        values = values.filter(({ key }) => {
          const { row, column, sheetName } = JSON.parse(key);
          return !(resolvedRow === row && resolvedColumn === column && resolvedSheetName === sheetName);
        });
      }
    });

    dataReferenceQueue.current = references;
    dataReferenceValues.current = values;
  }
}

export async function setDataReferenceCellTags(spreadsheet, dataReferences) {
  const tagsToUpdate = [];
  if (spreadsheet && dataReferences?.length > 0) {
    const sheetReferences = dataReferences.filter(({ sheetName }) => sheetName === spreadsheet.name());

    if (sheetReferences?.length > 0) {
      sheetReferences.forEach(reference => {
        const { id, type, row, column } = reference;
        let { value } = reference;

        if (!value) {
          value = spreadsheet.getValue(row, column);
        }

        const cellTag = getCellTag(spreadsheet, row, column);

        if (!cellTag?.references) {
          const cellTagObject = { references: [{ id, type }], value };
          setCellTag(spreadsheet, row, column, cellTagObject);
          tagsToUpdate.push(reference);
        } else {
          if (type !== CELL_REVIEW) {
            cellTag.references.forEach(cellReference => {
              if (cellReference.type === type && (cellReference.id !== id || reference.value !== cellTag.value)) {
                cellReference.id = id;
                cellTag.value = reference.value;
                tagsToUpdate.push(reference);
              }
              return cellReference;
            });

            if (!cellTag.references.some(ref => ref.type === type && ref.id === id)) {
              cellTag.references.push({ id, type });
            }
          } else {
            const reviewReferences = sheetReferences?.filter(
              x => x.row === row && x.column === column && x.type === CELL_REVIEW
            );
            reviewReferences.forEach(review => {
              const reviewTagExist = cellTag.references.some(x => x.id === review.id);
              if (!reviewTagExist) {
                cellTag.references.push({ id: review.id, type: review.type });
              }
            });
          }

          setCellTag(spreadsheet, row, column, cellTag);
        }
      });
    }
    return tagsToUpdate;
  }
}

export async function renderResolvedValues(spreadsheet, dataReferenceValues) {
  spreadsheet.suspendPaint();
  spreadsheet.suspendCalcService();
  dataReferenceValues.current.forEach(resolvedValue => {
    if (resolvedValue.key) {
      const { row, column, type } = JSON.parse(resolvedValue.key);
      resolvedValue['previousValue'] = spreadsheet.getValue(row, column);
      if (type === DATA_LINK) {
        spreadsheet.setValue(row, column, cleanDirtyCellValue(resolvedValue.value));
      } else {
        spreadsheet.recalcRange(row, column, 1, 1);
      }
    }
  });
  spreadsheet.resumeCalcService(false);
  spreadsheet.resumePaint();
}

function isValidDate(dateString) {
  for (let index = 0; index < momentDateFormats.length; index++) {
    const dateFormat = momentDateFormats[index];
    const parsedDate = moment(dateString, dateFormat.toString(), true);
    if (parsedDate.isValid()) {
      return true;
    }
  }
  return false;
}

function timeZoneInstance(dateString) {
  const charactersAfterTime = dateString.toString().match(/(\d{2}:\d{2}:\d{2})\s(.*)/);

  return charactersAfterTime ? charactersAfterTime[2] : undefined;
}

export function cleanDirtyCellValue(value, isApiTrigger = false) {
  const isPercentage = /^\d+(\.\d+)?%$/.test(value);

  if ((typeof value === 'string' || value instanceof String || value instanceof Date) && !isPercentage) {
    if (!isNaN(Date.parse(value))) {
      const timeZone = timeZoneInstance(value);
      const dateWithoutTimeZone = (timeZone ? value.toString().replace(timeZone, '') : value).trim();
      const isValidDateValue = isValidDate(dateWithoutTimeZone);
      if (isValidDateValue) {
        return isApiTrigger
          ? moment(new Date(dateWithoutTimeZone).toLocaleDateString()).format('MM/DD/YYYY')
          : new Date(dateWithoutTimeZone).toLocaleDateString();
      }
    }
  }

  return value;
}

export function generateEditCellSheetChanges(spreadsheet, metadata, skipUndo = false) {
  const dirtyCellsToUpdate = [];
  const dirtyCells = spreadsheet?.getDirtyCells();

  if (dirtyCells?.length > 0) {
    dirtyCells.forEach(({ row, col, oldValue, newValue }) => {
      const currentCell = metadata.find(x => x.row === row && x.column === col);
      const dirtyCellExist = dirtyCellsToUpdate.some(
        x => x.row === row && x.col === col && (x.isEmptyRow === true || x.skipUndo === true)
      );

      if (!dirtyCellExist) {
        const dirtyCommand = {
          sheetArea: 3,
          type: 'updateDirty',
          row,
          col,
          oldValue: cleanDirtyCellValue(oldValue),
          newValue: cleanDirtyCellValue(newValue),
        };

        if (currentCell) {
          dirtyCommand.isEmptyRow = true;
        } else {
          dirtyCommand.skipUndo = skipUndo;
          dirtyCommand.oldCalcValue = cleanDirtyCellValue(oldValue);
        }

        dirtyCellsToUpdate.push(dirtyCommand);
      }
    });
  }

  return dirtyCellsToUpdate;
}

export async function prepareBatchTagCommands(spreadsheet, references) {
  const commands = [];

  if (references?.length > 0) {
    references.forEach(({ row, column }) => {
      const cellTag = getCellTag(spreadsheet, row, column);
      const command = setValueAndTagOnServer(spreadsheet, row, column, JSON.stringify(cellTag));
      delete command.sheetNames;
      commands.push({ commandText: JSON.stringify(command) });
    });
  }

  return commands;
}
