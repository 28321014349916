import { useRef } from 'react';
import { DATA_LINK } from '../../_shared/DataReference/ReferenceType';
import { getWorkpaperOutSyncDataReferences } from './DataReference/apis';

/**
 * The interval (in ms) for re-checking out-of-sync data links.
 */
export const OUT_OF_SYNC_CHECK_INTERVAL = 3000;

/**
 * Class name of the synchronize changes button
 */
export const DATA_LINK_SYNC_BUTTON_CLASSNAME = 'data-link-sync-button';

/**
 * Custom hook for automatically checking if data links are out-of-sync
 * and injecting or removing a "Synchronize changes" button in the DOM.
 *
 * @param {Object} params
 * @param {string} params.workpaperId - The ID of the current workpaper.
 *
 * @returns {Object} An object containing:
 *   startOutSyncDataLinksCheck - function to trigger the sync check.
 *   stopOutSyncDataLinksCheck - function to stop the sync check.
 */
export default function useDataLinkSync({ workpaperId }) {
  const outOfSyncCheckRef = useRef(null);
  const screenWidthRef = useRef(null);

  /**
   * Injects the "Synchronize changes" button and sets up tooltip behavior.
   */
  const injectDataLinkSyncButtonHTML = () => {
    console.log('injectDataLinkSyncButtonHTML');
    const spreadJsRibbonWrapper = document.getElementsByClassName('ribbon-navigation')[0];
    if (!spreadJsRibbonWrapper) {
      console.warn('No element with class "ribbon-navigation" found.');
      return;
    }

    // If the button already exists, remove it to avoid duplicates
    const existingButton = document.querySelector(`.${DATA_LINK_SYNC_BUTTON_CLASSNAME}`);
    if (existingButton) {
      existingButton.remove();
    }

    // Icon
    const icon = document.createElement('i');

    icon.classList.add('fa', 'fa-download', 'data-link-sync-button-icon');

    // Button Container
    const spanContainer = document.createElement('span');
    spanContainer.classList.add(DATA_LINK_SYNC_BUTTON_CLASSNAME);

    // Append icon and text to the button
    spanContainer.appendChild(icon);
    spanContainer.appendChild(document.createTextNode('Synchronize changes'));

    // Add button to the DOM
    spreadJsRibbonWrapper.appendChild(spanContainer);

    // Store the current screen width in ref
    const screenWidth = window.innerWidth;
    screenWidthRef.current = screenWidth;

    // Position button on the right side
    const spanContainerWidth = spanContainer.offsetWidth;
    const rightMargin = 2;
    const leftPosition = screenWidth - spanContainerWidth - rightMargin;
    spanContainer.style.left = `${leftPosition}px`;

    // Tooltip
    const tooltip = document.createElement('div');
    tooltip.textContent =
      'Changes have been made to this workpaper which have not been synchronized forward to linked workpapers.';

    tooltip.classList.add('data-link-sync-button-tooltip');

    tooltip.style.maxWidth = `${spanContainerWidth - 41}px`; // Avoid sidebar overlap

    // Tooltip arrow
    const arrow = document.createElement('div');
    arrow.classList.add('data-link-sync-button-tooltip-arrow');

    tooltip.appendChild(arrow);

    // Add tooltip to dom
    document.body.appendChild(tooltip);

    // Position tooltip based on button rect
    const buttonRect = spanContainer.getBoundingClientRect();
    tooltip.style.top = `${buttonRect.bottom}px`;
    tooltip.style.left = `${buttonRect.left}px`;

    // Tooltip event listeners
    spanContainer.addEventListener('mouseenter', () => {
      tooltip.style.visibility = 'visible';
      tooltip.style.opacity = '1';
    });

    spanContainer.addEventListener('mouseleave', () => {
      tooltip.style.visibility = 'hidden';
      tooltip.style.opacity = '0';
    });
  };

  /**
   * Checks if there are any out-of-sync data links and updates the DOM accordingly.
   */
  const startOutSyncDataLinksCheck = async () => {
    try {
      console.log('outSyncDataLinksCheck');

      // Clear any pending timeout
      if (outOfSyncCheckRef.current) {
        clearTimeout(outOfSyncCheckRef.current);
      }

      // Fetch out-of-sync data links
      const outOfSyncDataLinks = await getWorkpaperOutSyncDataReferences({
        workpaperId,
        filters: new Map([
          ['referenceType', DATA_LINK],
          ['includeDataLinksSources', true],
        ]),
      });

      // Filter out non-outgoing data links, so the target workpaper does not show the sync button
      const outGoingDataLinks = outOfSyncDataLinks.filter(dataLink => dataLink.workpaperId !== workpaperId);

      const syncButton = document.querySelector(`.${DATA_LINK_SYNC_BUTTON_CLASSNAME}`);

      if (outGoingDataLinks?.length) {
        // If out-of-sync data exists
        if (syncButton) {
          // Check if screen width changed
          const screenWidth = window.innerWidth;
          if (screenWidthRef.current !== screenWidth) {
            // Re-render button
            syncButton.remove();
            injectDataLinkSyncButtonHTML();
          }
        } else {
          injectDataLinkSyncButtonHTML();
        }
      } else if (syncButton) {
        // If no out-of-sync data exists, remove button if it exists
        syncButton.remove();
      }
    } catch (error) {
      console.error('Error during outSyncDataLinksCheck:', error);
    } finally {
      // Schedule next check
      outOfSyncCheckRef.current = setTimeout(startOutSyncDataLinksCheck, OUT_OF_SYNC_CHECK_INTERVAL);
    }
  };

  /**
   * Stops the out-of-sync data links check by clearing the timeout.
   */
  const stopOutSyncDataLinksCheck = () => {
    if (outOfSyncCheckRef.current) {
      clearTimeout(outOfSyncCheckRef.current);
      outOfSyncCheckRef.current = null;
    }
  };

  return {
    startOutSyncDataLinksCheck,
    stopOutSyncDataLinksCheck,
  };
}
