import { useContext, useEffect, useRef } from 'react';
import EditorContext from './EditorContext';
import { useMetadata } from './Spreadsheet/SideBar/SettingsPanel/useMetadata';
import GC from '../../../SpreadSheets';
import { useEditorToggle } from './useEditorToggle';
import { createWorkpaperDataReferences } from './DataReference/apis';
import { DATA_LINK } from '../../_shared/DataReference/ReferenceType';
import { v4 as uuidv4 } from 'uuid';
import { DATA_LINK_ADDED } from './HistoryTracker/useHistoryTracker';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { DATA_LINKS } from '../../../constants/featureFlags';
import { setValueOnServer } from './useWorkpaper/processCommand';
import { cleanDirtyCellValue } from './DataReference/dataReferenceHelper';
import { BroadcastChannels, EventTypes } from './useBroadcast/constants';
import { applyTimestamp } from '../../../sjs-cmd-process';
import useDataLinkSync from './useDataLinkSync';

export function toA1Notation(row, column, isRelative = false) {
  const charCodeA = 65;
  const alphabetLength = 26;
  let columnLabel = '';

  for (let c = column; c >= 0; c = Math.floor(c / 26) - 1) {
    const transposition = c % alphabetLength;
    columnLabel = String.fromCharCode(charCodeA + transposition) + columnLabel;
  }

  const rowLabel = row + 1;
  return isRelative ? `${columnLabel}${rowLabel}` : `$${columnLabel}$${rowLabel}`;
}

export function useDataLink({ workpaperId }) {
  const targetWorkbookStorage = 'linking.target';
  const sourceWorkbookStorage = 'linking.source';
  const endDataLinkCommandName = 'endDataLink';
  const sourceSelectionColor = '#AB63DB';
  const defaultSelectionColor = '#0073FF';
  const storageListener = useRef(null);
  const unsubscribeSourceUpdateListener = useRef(null);
  const unsubscribeTargetCreatedListener = useRef(null);
  const isDataLinksEnabled = isFeatureFlagEnabled(DATA_LINKS);

  const {
    spreadRef,
    setDataLinkStatus,
    addToHistory,
    enqueueCommands,
    dataReferences,
    enqueueBatchDataReference,
    createChannel,
    sendMessage,
    subscribeToChannel,
  } = useContext(EditorContext);
  const { getMetadata } = useMetadata({ workpaperId });
  const { disableEditor, enableEditor } = useEditorToggle();
  const { startOutSyncDataLinksCheck, stopOutSyncDataLinksCheck } = useDataLinkSync({ workpaperId });
  const datalinkFormulaRegex = /^[+-]*'(?:([\d]{4})\/)?\[([^\]]+)]([^']+)'!\$([A-Z]+\$\d+)$/;

  // Utils
  function isValidFormula(formula) {
    return datalinkFormulaRegex.test(formula);
  }

  function toRowAndColumn(a1Notation) {
    const charCodeA = 65;
    const alphabetLength = 26;
    const cleanedA1 = a1Notation.toUpperCase().replaceAll('$', '');

    const match = cleanedA1.match(/^([A-Z]+)(\d+)$/);

    if (!match) {
      throw new Error('Invalid A1 notation');
    }

    const columnLabel = match[1];
    const rowLabel = match[2];

    let column = 0;
    for (let i = 0; i < columnLabel.length; i++) {
      column = column * alphabetLength + (columnLabel.charCodeAt(i) - charCodeA + 1);
    }
    column -= 1;

    const row = parseInt(rowLabel) - 1;

    return { row, column };
  }

  function extractFormulaValues(formula) {
    const match = formula.match(datalinkFormulaRegex);
    const { row, column } = toRowAndColumn(match[4]);

    if (!match) {
      throw new Error('Invalid formula format');
    }
    return {
      taxPeriod: match[1] || null,
      name: match[2],
      sheetName: match[3],
      a1Coordinate: `$${match[4]}`,
      row,
      column,
    };
  }

  async function getRequestParameters(name, row, column, sheetName, params) {
    const {
      taxPeriod: sourceTaxPeriod,
      name: sourceWorkpaperName,
      sheetName: sourceSheetName,
      a1Coordinate,
      row: sourceRow,
      column: sourceColumn,
    } = params;

    const sourceParameters = {
      ...(sourceTaxPeriod && { taxPeriod: sourceTaxPeriod }),
      workpaperName: sourceWorkpaperName,
      sheetName: sourceSheetName,
      a1Coordinate,
      row: sourceRow,
      column: sourceColumn,
    };

    const requestParameters = {
      row,
      column,
      type: DATA_LINK,
      sheetName,
      name,
      id: uuidv4(),
      workpaperId,
      parameters: sourceParameters,
    };

    return requestParameters;
  }

  async function findDataReference({ sheetName, row, col }) {
    const timeout = 30000;
    return new Promise((resolve, reject) => {
      let elapsedTime = 0;
      const intervalId = setInterval(() => {
        const reference = dataReferences.current.find(
          ref =>
            ref.type === DATA_LINK &&
            ref.sheetName === sheetName &&
            ref.row === row &&
            ref.column === col &&
            ref.value !== undefined
        );

        elapsedTime += 1000;

        if (reference) {
          clearInterval(intervalId);
          resolve(reference);
        } else if (elapsedTime >= timeout) {
          clearInterval(intervalId);
          return [];
        }
      }, 1000);
    });
  }

  async function handleFinishDataLink() {
    const sheet = spreadRef.current.getActiveSheet();
    const [{ row, col: column }] = sheet.getSelections();
    const sheetName = sheet.name();
    const value = sheet.getValue(row, column);
    const { taxPeriod, name } = await getMetadata();

    localStorage.setItem(
      sourceWorkbookStorage,
      JSON.stringify({
        workpaperId,
        taxPeriod,
        name,
        sheetName,
        row,
        column,
        value,
        isFinal: true,
      })
    );
  }

  function sourceWorkbookHandler(linkStorageState) {
    if (linkStorageState && linkStorageState.workpaperId !== workpaperId) {
      setDataLinkStatus({ active: true, targetWorkpaperName: linkStorageState.workpaperName });
      disableEditor();
      spreadRef.current.commandManager().setShortcutKey('commitInputNavigationDown', false);
      spreadRef.current.commandManager().setShortcutKey(endDataLinkCommandName, GC.Spread.Commands.Key.enter);
      spreadRef.current.getActiveSheet().options.selectionBorderColor = sourceSelectionColor;
    } else {
      setDataLinkStatus({ active: false, targetWorkpaperName: null });
      enableEditor();
      spreadRef.current.commandManager().setShortcutKey('commitInputNavigationDown', GC.Spread.Commands.Key.enter);
      spreadRef.current.commandManager().setShortcutKey(endDataLinkCommandName, false);
      spreadRef.current.getActiveSheet().options.selectionBorderColor = defaultSelectionColor;
    }
  }

  function targetWorkbookHandler(linkStorageState, event) {
    if (linkStorageState && linkStorageState.workpaperId === workpaperId) {
      const {
        workpaperId: sourceId,
        taxPeriod,
        name,
        sheetName,
        row: sourceRow,
        column: sourceColumn,
        value,
        isFinal,
      } = JSON.parse(event.newValue);
      const { row: targetRow, column: targetColumn, previousValue, isNegative } = linkStorageState;

      const effectiveTaxPeriod = taxPeriod ? `${taxPeriod}/` : '';

      const a1Coordinate = toA1Notation(sourceRow, sourceColumn);

      const sign = isNegative ? '-' : '';

      const formula = `${sign}'${effectiveTaxPeriod}[${name}]${sheetName}'!${a1Coordinate}`;

      const ss = spreadRef.current;
      const sheet = ss.getActiveSheet();

      sheet.setFormula(targetRow, targetColumn, formula);
      if (!isFinal) return;
      sheet.setFormula(targetRow, targetColumn, null);

      const partialWorkbook = {
        [sheetName]: {
          [sourceRow]: {
            [sourceColumn]: value,
          },
        },
      };
      const command = {
        cmd: 'editCell',
        sheetName: sheet.name(),
        row: targetRow,
        col: targetColumn,
        applyResult: 0,
        newValue: `=${formula}`,
        activeRowIndex: targetRow,
        activeColIndex: targetColumn,
        actionType: 0,
        partialWorkbook,
        workpaperName: name,
        taxPeriod: effectiveTaxPeriod,
      };

      ss.commandManager().execute(command);
      addToHistory(
        {
          cmd: DATA_LINK_ADDED,
          undo: () => {
            ss.undoManager().undo();
            sheet.setValue(targetRow, targetColumn, previousValue);
          },
        },
        ss
      );
      ss.updateExternalReference(name, partialWorkbook, effectiveTaxPeriod, true);
      const referenceId = uuidv4();
      const dataLinkReference = {
        workpaperId,
        row: targetRow,
        column: targetColumn,
        referenceId,
        id: referenceId,
        sheetName: sheet.name(),
        referenceType: DATA_LINK,
        type: DATA_LINK,
        oldValue: value,
        newValue: value,
        value,
        parameters: JSON.stringify({
          workpaperId: sourceId,
          taxPeriod,
          workpaperName: name,
          sheetName,
          a1Coordinate,
          row: sourceRow,
          column: sourceColumn,
        }),
      };
      createWorkpaperDataReferences(workpaperId, [dataLinkReference]);
      dataReferences.current.push(dataLinkReference);
      sendMessage(BroadcastChannels.TargetCreatedReference, {
        type: EventTypes.DataLinkCreated,
        details: dataLinkReference,
      });
      sheet.endEdit(true);
    }
  }

  function setupSourcesUpdateChannelListener() {
    function listener(event) {
      const updatedReferences = event.data.details;
      if (updatedReferences?.length) {
        const filteredReferences = updatedReferences.filter(ref => ref.workpaperId === workpaperId);
        dataReferences.current = dataReferences.current.map(
          ref => filteredReferences.find(r => r.id === ref.id) ?? ref
        );
      }
    }
    const unsubscribeListener = subscribeToChannel(
      BroadcastChannels.SourceUpdateReferences,
      EventTypes.DataLinkSourceUpdated,
      listener
    );
    return unsubscribeListener;
  }

  function setupTargetCreatedChannelListener() {
    function listener(event) {
      const createdReference = event.data.details;
      const params = JSON.parse(createdReference.parameters);
      if (params.workpaperId === workpaperId) {
        dataReferences.current.push(createdReference);
        console.log('Data Link Added', 'startOutSyncDataLinksCheck');
        startOutSyncDataLinksCheck();
      }
    }
    const unsubscribeListener = subscribeToChannel(
      BroadcastChannels.TargetCreatedReference,
      EventTypes.DataLinkCreated,
      listener
    );
    return unsubscribeListener;
  }

  function setupStorageListener() {
    if (!window) return;
    function listener(event) {
      if (event.storageArea === localStorage) {
        const linking = JSON.parse(localStorage.getItem(targetWorkbookStorage));
        switch (event.key) {
          case targetWorkbookStorage: {
            sourceWorkbookHandler(linking);
            break;
          }
          case sourceWorkbookStorage: {
            targetWorkbookHandler(linking, event);
            break;
          }
          default:
            break;
        }
      }
    }
    window.addEventListener('storage', listener);
    return listener;
  }

  function sourceWorkbookSpreadListeners(readOnly) {
    async function setDataLinkSource(row, column, sheetName, value) {
      const { taxPeriod, name } = await getMetadata();

      localStorage.setItem(
        sourceWorkbookStorage,
        JSON.stringify({
          workpaperId,
          taxPeriod,
          name,
          sheetName,
          row,
          column,
          value,
        })
      );
    }
    const ss = spreadRef.current;
    if (!ss | readOnly) return;
    ss.bind(GC.Spread.Sheets.Events.SelectionChanged, function (e, { sheet, newSelections, sheetName }) {
      const linking = JSON.parse(localStorage.getItem(targetWorkbookStorage));
      if (linking && workpaperId !== linking.workpaperId) {
        const [{ row, col: column, colCount, rowCount }] = newSelections;
        if (colCount > 1 || rowCount > 1) {
          GC.Spread.Sheets.Designer.showMessageBox(
            'Invalid formula',
            'Warning',
            GC.Spread.Sheets.Designer.MessageBoxIcon.warning
          );
        }
        const value = sheet.getValue(row, column);
        setDataLinkSource(row, column, sheetName, value);
      }
    });

    ss.bind(GC.Spread.Sheets.Events.ActiveSheetChanged, function (e, { newSheet, oldSheet }) {
      const linking = JSON.parse(localStorage.getItem(targetWorkbookStorage));
      if (linking && workpaperId !== linking.workpaperId) {
        oldSheet.options.selectionBorderColor = defaultSelectionColor;
        newSheet.options.selectionBorderColor = sourceSelectionColor;
        const [{ row, col }] = newSheet.getSelections();
        const value = newSheet.getValue(row, col);
        const cellData = JSON.parse(localStorage.getItem(sourceWorkbookStorage));
        cellData.sheetName = newSheet.name();
        cellData.row = row;
        cellData.column = col;
        cellData.value = value;
        localStorage.setItem(sourceWorkbookStorage, JSON.stringify(cellData));
      }
    });
  }

  function targetWorkbookSpreadListener(readOnly) {
    async function setDataLinkTarget(row, column, previousValue, isNegative) {
      const { name } = await getMetadata();
      localStorage.setItem(
        targetWorkbookStorage,
        JSON.stringify({
          workpaperId,
          workpaperName: name,
          row,
          column,
          previousValue,
          isNegative,
        })
      );
    }
    const ss = spreadRef.current;

    if (!ss || readOnly) return;
    ss.bind(GC.Spread.Sheets.Events.EditChange, function (e, { sheet, row, col, editingText }) {
      if (/=[-+]*/.test(editingText)) {
        const isNegativeFlag = (editingText.match(/-/g) || []).length % 2 !== 0;
        setDataLinkTarget(row, col, sheet.getValue(row, col), isNegativeFlag);
      } else {
        localStorage.removeItem(targetWorkbookStorage);
        localStorage.removeItem(sourceWorkbookStorage);
      }
    });

    ss.bind(GC.Spread.Sheets.Events.EditEnded, function (e, a) {
      localStorage.removeItem(targetWorkbookStorage);
      localStorage.removeItem(sourceWorkbookStorage);
    });

    async function updateFormulaEventHandler(e, args) {
      const { sheet } = args;
      let reference;
      if (e.type === 'CellClick') {
        if (!dataReferences.current?.length) return;
        const { row, col } = args;
        reference = dataReferences.current.find(
          ref => ref.type === DATA_LINK && ref.sheetName === sheet.name() && ref.row === row && ref.column === col
        );
      } else if (e.type === 'SelectionChanged') {
        if (!dataReferences.current?.length) return;
        const { newSelections } = args;
        reference = dataReferences.current.find(
          ref =>
            ref.type === DATA_LINK && ref.sheetName === sheet.name() && newSelections[0].contains(ref.row, ref.column)
        );
      } else if (e.type === 'UserFormulaEntered') {
        const { sheet, row, col, formula, sheetName } = args;

        if (isValidFormula(formula)) {
          const refOnCell = dataReferences.current.find(
            r => r.sheetName === sheetName && r.row === row && r.column === col
          );
          const params = extractFormulaValues(formula);
          if (refOnCell) {
            const { taxPeriod, name, sheetName, a1Coordinate } =
              typeof refOnCell.parameters === 'string' ? JSON.parse(refOnCell.parameters) : refOnCell.parameters;
            if (
              params.taxPeriod === taxPeriod &&
              params.name === name &&
              params.sheetName === sheetName &&
              params.a1Coordinate === a1Coordinate
            )
              return;
          }

          const { name } = await getMetadata();

          const request = await getRequestParameters(name, row, col, sheetName, params);
          enqueueBatchDataReference([request]);
          reference = await findDataReference({ sheetName, row, col });
          reference.parameters = JSON.stringify(params);
        } else {
          sheet.setValue(row, col, GC.Spread.CalcEngine.Errors.NotAvailable);
        }
      } else if (e.type === 'ClipboardPasted') {
        const batchRequests = [];
        const { name } = await getMetadata();
        const { cellRange, sheetName } = args;
        const { row, col, rowCount, colCount } = cellRange;
        for (let r = 0; r < rowCount; r++) {
          for (let c = 0; c < colCount; c++) {
            const selectedRow = row + r;
            const selectedCol = col + c;
            const formula = sheet.getFormula(selectedRow, selectedCol);
            if (isValidFormula(formula)) {
              const params = extractFormulaValues(formula);
              const requestParameters = await getRequestParameters(name, selectedRow, selectedCol, sheetName, params);
              batchRequests.push(requestParameters);
            }
          }
        }

        enqueueBatchDataReference(batchRequests);
        return;
      }
      if (!reference) return;

      const parameters =
        typeof reference.parameters === 'string' ? JSON.parse(reference.parameters) : reference.parameters;
      const { taxPeriod, a1Coordinate, sheetName, row, column } = parameters;
      const workpaperName = parameters.workpaperName ?? parameters.name;
      const effectiveTaxPeriod = taxPeriod ? `${taxPeriod}/` : '';
      const currentFormula = sheet.getFormula(reference.row, reference.column);
      const sign = currentFormula.startsWith('-') ? '-' : '';
      const formula = `=${sign}'${effectiveTaxPeriod}[${workpaperName}]${sheetName}'!${a1Coordinate}`;
      if (e.type !== 'UserFormulaEntered') {
        sheet.setFormula(reference.row, reference.column, formula);
      }

      const partialWorkbook = {
        [sheetName]: {
          [row]: {
            [column]: cleanDirtyCellValue(reference.value),
          },
        },
      };

      ss.updateExternalReference(workpaperName, partialWorkbook, effectiveTaxPeriod, true);
      const command = setValueOnServer(sheet, reference.row, reference.column, formula);
      command.partialWorkbook = partialWorkbook;
      command.workpaperName = workpaperName;
      command.taxPeriod = effectiveTaxPeriod;
      command.isUndoable = false;
      enqueueCommands([{ commandText: JSON.stringify(applyTimestamp(command)) }]);
      console.log('Data Link added', ' startOutSyncDataLinksCheck');
      startOutSyncDataLinksCheck();
    }

    ss.bind(GC.Spread.Sheets.Events.CellClick, updateFormulaEventHandler);
    ss.bind(GC.Spread.Sheets.Events.SelectionChanged, updateFormulaEventHandler);
    ss.bind(GC.Spread.Sheets.Events.UserFormulaEntered, updateFormulaEventHandler);
    ss.bind(GC.Spread.Sheets.Events.ClipboardPasted, updateFormulaEventHandler);
  }

  function setupDataLinkSpreadListeners(readOnly) {
    if (readOnly) {
      removeListeners();
    } else {
      setUpListeners();
    }
    targetWorkbookSpreadListener(readOnly);
    sourceWorkbookSpreadListeners(readOnly);

    spreadRef.current.commandManager().register(endDataLinkCommandName, { execute: handleFinishDataLink });
  }

  function setUpListeners() {
    storageListener.current = setupStorageListener();
    unsubscribeSourceUpdateListener.current = setupSourcesUpdateChannelListener();
    unsubscribeTargetCreatedListener.current = setupTargetCreatedChannelListener();
    const dataLinkTypeReferences = dataReferences.current.filter(({ type }) => type === DATA_LINK);
    if (dataLinkTypeReferences.length) {
      const outGoingDataLinks = dataLinkTypeReferences.filter(dataLink => dataLink.workpaperId !== workpaperId);

      if (outGoingDataLinks) {
        console.log('out going Data Link type reference found in Workpaper', 'startOutSyncDataLinksCheck');
        startOutSyncDataLinksCheck();
      }
    }
  }

  function removeListeners() {
    if (storageListener.current) window.removeEventListener('storage', storageListener.current);
    storageListener.current = null;
    unsubscribeSourceUpdateListener.current?.();
    unsubscribeSourceUpdateListener.current = null;
    unsubscribeTargetCreatedListener.current?.();
    unsubscribeTargetCreatedListener.current = null;
    setDataLinkStatus({ active: false, targetWorkpaperName: null });
  }

  useEffect(() => {
    if (window?.BroadcastChannel) {
      createChannel(BroadcastChannels.SourceUpdateReferences);
      createChannel(BroadcastChannels.TargetCreatedReference);
    }

    return () => {
      try {
        if (isDataLinksEnabled) {
          removeListeners();
          stopOutSyncDataLinksCheck();
        }
      } catch (error) {}
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { setupDataLinkSpreadListeners };
}
