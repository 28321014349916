import { BTButton, BTComboBox, BTForm } from '@btas/jasper';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Frame from '../_shared/Frame';
import GeneralErrorMessage from '../_shared/GeneralErrorMessage';
import Spinner from '../_shared/Spinner';
import { getUser } from '../../components/_shared/auth';
import { createUserToken, getClients } from './ClientSelection/apis.js';
import { clientAccessManagerUrl } from '../../configs/params.js';
import { roles, hasUserRole } from '../../utils/userRoles';
import { isFeatureFlagEnabled } from '../../utils/featureFlags';
import { WKP_LANDING_ON_DATAFLOW } from '../../constants/featureFlags';
import './ClientSelection/styles.scss';
import { useClient } from '../../contexts/ClientContext.js';

export default function ClientSelection() {
  const [isLoading, setIsLoading] = useState(false);
  const [clientsError, setClientsError] = useState(false);
  const [clients, setClients] = useState([]);
  const history = useHistory();
  const [clientOptions, setClientOptions] = useState([]);
  const { companyId } = getUser() || {};
  const [selectedClient, setSelectedClient] = useState(null);
  const { clientId, setClientId } = useClient();

  const defaultPath = isFeatureFlagEnabled(WKP_LANDING_ON_DATAFLOW) ? '/data-flows' : '/workpapers';

  const isCpocAdmin = hasUserRole(roles.CPOC_ADMIN);

  const getRedirectParameter = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const returnUrls = queryParameters.get('redirectUrl');
    let returnUrl;
    if (returnUrls) {
      const containsReturnUrl = returnUrls.includes('?');
      const splittedUrls = containsReturnUrl ? returnUrls.split('?') : [returnUrls];
      const cleanReturnUrl = splittedUrls.length > 1 ? splittedUrls[splittedUrls.length - 1] : splittedUrls[0];
      returnUrl = cleanReturnUrl.includes('redirectUrl=') ? cleanReturnUrl.replace('redirectUrl=', '') : cleanReturnUrl;
    }
    return returnUrl;
  };

  useEffect(() => {
    const setInitialData = async () => {
      try {
        setIsLoading(true);
        const clients = await getClients(companyId);
        setClients(clients);
        //user has no clients
        if (!clients.length) {
          history.replace('/no-client-access');
          return;
        } else if (clients?.length === 1) {
          //skip client selection
          const clientInfo = clients[0];
          setClientId(clientInfo?.id);
          return;
        }
        //user has more than 1 client
        const clientOptions = clients.map(c => ({ label: c.name, value: c.name }));
        setClientOptions(clientOptions);
        setSelectedClient(clientOptions[0]);
      } catch (err) {
        setClientsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    const checkClientId = () => {
      if (clientId) {
        goToRedirectUrl();
      }
    };

    checkClientId();
    setInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function createToken() {
      if (clientId) {
        // set user context cookie
        await createUserToken(clientId); //TODO HANDLE ERROR
        //redirect to application
        goToRedirectUrl();
      }
    }
    createToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  const goToRedirectUrl = () => {
    const redirectUrl = getRedirectParameter();
    let target = redirectUrl ?? defaultPath;
    // Convert target to a URL object
    const url = new URL(target, window.location.origin);

    // Extract the relative path and query string
    const relativePath = url.pathname + url.search;
    history.replace(relativePath);
  };

  const onClientChange = c => {
    if (c && c.value) {
      setSelectedClient(c);
    }
  };

  const onClickOpenClient = () => {
    //set current client id in local storage
    const clientInfo = clients.find(c => c.name === selectedClient.value);
    setClientId(clientInfo?.id);
  };

  return (
    <Frame showAppBar={false}>
      <div className="client-selection-container">
        {isLoading && <Spinner />}
        {!isLoading && (
          <>
            <h1>Welcome to Bloomberg Tax Workpapers</h1>
            <div className="select-client-box">
              <h2>Select a client to begin</h2>
              <div className="client-box-elements">
                <BTForm.FormGroup label="Client">
                  <BTComboBox
                    id="Client"
                    name="Client"
                    options={clientOptions}
                    value={selectedClient}
                    onChange={onClientChange}
                  ></BTComboBox>
                  <BTButton btStyle="primary" className="select-client-btn" onClick={onClickOpenClient}>
                    OPEN CLIENT
                  </BTButton>
                </BTForm.FormGroup>
              </div>
              {isCpocAdmin ? (
                <div className="helpText">
                  Don’t see the client you are looking for?
                  <a
                    className="item-menu-apps"
                    href={clientAccessManagerUrl}
                    rel="noreferrer"
                    role="menuitem"
                    target="_blank"
                  >
                    <span className="icon-menu-apps"> Open Client Access Manager </span>
                  </a>
                  to add or edit clients.
                </div>
              ) : (
                <div className="helpText">
                  Don’t see the client you are looking for? Contact your account administrator to add clients or to
                  request client access.
                </div>
              )}
            </div>
          </>
        )}
        <GeneralErrorMessage dismissible={true} visible={clientsError} />
      </div>
    </Frame>
  );
}
