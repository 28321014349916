import { userDataAPIPath } from '../../../configs/params';
import mockClients from './mockClients.json';
import * as fetch from '../../_shared/fetch';

export async function getClients(companyId) {
  const testCompanyId = '990000003';
  const emptyClientsCompanyId = '9990000006';
  const emptyClientsCompanyIdForCpocAdmin = '990000010';
  if (companyId === testCompanyId) {
    return [mockClients[0]];
  } else if (companyId === emptyClientsCompanyId || companyId === emptyClientsCompanyIdForCpocAdmin) {
    return [];
  } else {
    return mockClients;
  }
}

export async function createUserToken(clientId) {
  //TODO HANDLE ERROR
  await fetch.post(`${userDataAPIPath}/client/token`, {
    body: JSON.stringify({ clientId }),
  });
}
